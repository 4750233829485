import type { Person } from './dto';
import { PersonRoleEnum } from './dto';

// add richtext to a person, and when item click open the richtext as an article.!
export const persons: Person[] = [
    {
        id: '1',
        firstName: 'Rasmus',
        surname: 'Bjørnbak Linddahl',
        by: 'Hjørring',
        postNummer: '9800',
        vejNavn: 'Højene Enge',
        vejNummer: '38',
        tlfNr: '+45 ...',
        email: 'comming soon',
        image: 'pics/persons/foto.png',
        roles: [{ id: '1', role: PersonRoleEnum.Formand }]
    },
    {
        id: '3',
        firstName: 'Mikael',
        surname: 'Skov',
        by: 'Hjørring',
        postNummer: '9800',
        vejNavn: 'Højene Enge',
        vejNummer: '7',
        tlfNr: '+45 ...',
        email: 'comming soon',
        image: 'pics/persons/foto.png',
        roles: [{ id: '3', role: PersonRoleEnum.Bestyrelsesmedlem }]
    },
    {
        id: '4',
        firstName: 'Morten',
        surname: 'Nielsen',
        by: 'Hjørring',
        postNummer: '9800',
        vejNavn: 'Højene Enge',
        vejNummer: '5',
        tlfNr: '+45 ...',
        email: 'comming soon',
        image: 'pics/persons/foto.png',
        roles: [{ id: '4', role: PersonRoleEnum.Bestyrelsesmedlem }]
    },
    {
        id: '5',
        firstName: 'Kim',
        surname: 'Høholt',
        by: 'Hjørring',
        postNummer: '9800',
        vejNavn: 'Højene Enge',
        vejNummer: '49',
        tlfNr: '+45 ...',
        email: 'comming soon',
        image: 'pics/persons/foto.png',
        roles: [{ id: '5', role: PersonRoleEnum.Bestyrelsesmedlem }]
    },
    {
        id: '6',
        firstName: 'Ilse',
        surname: 'Høholt',
        by: 'Hjørring',
        postNummer: '9800',
        vejNavn: 'Højene Enge',
        vejNummer: '49',
        tlfNr: '+45 ...',
        email: 'comming soon',
        image: 'pics/persons/foto.png',
        roles: [{ id: '6', role: PersonRoleEnum.Revisor }]
    },
    {
        id: '2',
        firstName: 'Kasper',
        surname: 'Vorre Nielsen',
        by: 'Hjørring',
        postNummer: '9800',
        vejNavn: 'Højene Enge',
        vejNummer: '40',
        tlfNr: '+45 ...',
        email: 'comming soon',
        image: 'pics/persons/foto.png',
        roles: [{ id: '2', role: PersonRoleEnum.Kasserer }]
    },
    {
        id: '7',
        firstName: 'Lars',
        surname: 'Sander',
        by: 'Hjørring',
        postNummer: '9800',
        vejNavn: 'Højene Enge',
        vejNummer: '11',
        tlfNr: '+45 ...',
        email: 'comming soon',
        image: 'pics/persons/foto.png',
        roles: [{ id: '7', role: PersonRoleEnum.Suppleant }]
    }
];

export const filterPersonsBasedOnRole = (persons: Person[], includeRoles?: PersonRoleEnum[]): Person[] =>
    persons.filter((person) => {
        if (!includeRoles) {
            return true;
        }
        return !!person.roles.find((role) => includeRoles.includes(role.role));
    });

export const getIconBasedOnRole = (): string => '';
