import { ColorTokens } from 'src/utilities/tokens/tokens';

import styles from './image-text-story.module.scss';

export const ImageTextStory = (): JSX.Element => (
    <section style={{ backgroundColor: ColorTokens.GREY_LIGHT, display: 'flex' }}>
        <div className={`container g-0`}>
            <div className={`row g-0`}>
                {/* col 1 */}
                <div className={`col-12 col-md-6`}>
                    <div className={`${styles.textContainer}`}>
                        <h5>Om Højene Enge</h5>
                        <h2>Højene Enge - en helt ny bydel</h2>
                        <p>
                            Højene Enge er udviklet som et moderne boligområde, hvor gode forbindelser, sammenhæng og rekreative udfoldelsesmuligheder
                            udgør rammen om det gode liv.
                        </p>
                        <p>
                            Højene Øst har et veludbygget stisystem, hvor børn kan bevæge sig sikkert rundt i området, til og fra skole og
                            fritidsinteresser.
                        </p>
                        <p>
                            Stierne forbinder de forskellige boligområder til det fælles grønne rekreative areal mod Ringvejen - Højene Fælles.
                            Området skaber en forbindelse langs den nye bydels vestligste grænse, og kombinerer støj- og visuel afskærmning mod
                            Ringvejen med regnvandsløsninger og rekreative aktiviteter der skaber merværdi for området, og gør Højene Enge til et
                            dejligt sted at bo!
                        </p>
                    </div>
                </div>
                <div className={`col-12 col-md-6 ${styles.imageContainer}`}>
                    <img loading='lazy' src='pics/story1.jpg' alt='Woman on blanket' className={`${styles.image}`} />
                </div>
                {/* col 2 */}
                <div className={`col-12 col-md-6 ${styles.imageContainer}`}>
                    <img loading='lazy' src='pics/story2.jpg' alt='Woman on blanket' className={`${styles.image}`} />
                </div>
                <div className={`col-12 col-md-6`}>
                    <div className={`${styles.textContainer}`}>
                        <h5>Hvorfor byudvikle i</h5>
                        <h2>Højene Øst</h2>
                        <p>
                            En ny befolkningsprognose fra 2018 for Hjørring Kommune viser, at Hjørring by over en kommende 12 års periode kan forvente
                            at blive ca. 1.350 flere indbyggere.
                        </p>
                        <p>
                            De skal have et sted at bo og Hjørrings eksisterende borgere skal have mulighed for at flytte rundt i byen - til et godt
                            og attraktivt sted i byen!
                        </p>
                        <p>
                            Derfor er der behov for en helhedsorienteret planlægning, der kan være med til at skabe attraktive grunde og boliger i
                            byen.
                        </p>
                    </div>
                </div>
                {/* 3 */}
                <div className={`col-12 col-md-6`}>
                    <div className={`${styles.textContainer}`}>
                        <h5>Lokation</h5>
                        <h2>Området</h2>
                        <p>
                            Byudviklingsområdet ”Højene Øst” ligger i den nordøstlige del af Hjørring umiddelbart vest for Ringvejen, der skaber en
                            let tilgængelighed til byens overordnede vejnet og motorvejsforbindelse.
                        </p>
                        <p>
                            Området består af en blanding af kommunale og private arealer. Området ligger tæt ved skole, institutioner, idrætsområdet,
                            rekreative områder, dagligvarebutikker og i en afstand på kun 2,5 km til Hjørring bymidte.
                        </p>
                        <p>
                            Derfor har området optimale betingelser for den fremtidige udbygning, når omgivelserne indarbejdes som aktiver i
                            planlægningen.
                        </p>
                    </div>
                </div>
                <div className={`col-12 col-md-6 ${styles.mapContainer}`}>
                    <iframe
                        loading='lazy'
                        title='googleMap'
                        src='https://maps.google.com/maps?q=57.4787413,10.0027362&z=17&output=embed&layer=c'></iframe>
                </div>
            </div>
        </div>
    </section>
);
