export type Person = {
    id: string;
    firstName: string;
    surname: string;
    vejNavn: string;
    vejNummer: string;
    postNummer: string;
    by: string;
    tlfNr?: string;
    email?: string;
    image: string;
    roles: PersonRole[];
};

export type PersonRole = {
    id: string;
    role: PersonRoleEnum;
    image?: string;
};

export enum PersonRoleEnum {
    HovedSekretær = 'Hovedsekretær',
    Sekretær = 'Sekretær',
    Hovedkasserer = 'Hovedkasserer',
    Kasserer = 'Kasserer',
    Hovedformand = 'Hovedformand',
    Formand = 'Formand',
    NæstFormand = 'Næstformand',
    Bestyrelsesmedlem = 'Bestyrelsesmedlem',
    Suppleant = 'Suppleant',
    Revisor = 'Revisor'
}
