import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageIcon from '@mui/icons-material/Image';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import type { FileProps } from 'src/data/files/dto';
import { allØverigeDokumenter, allRapporter, allReferater, allRegnskaber, allVedtægter } from 'src/data/files/files';

import styles from './document-viewer.module.scss';

type AccordionProps = {
    id: string;
    title: string;
    files: FileProps[];
};

const accordions: AccordionProps[] = [
    {
        id: 'Referater',
        title: 'Referater',
        files: allReferater
    },
    {
        id: 'Vedtægter',
        title: 'Vedtægter',
        files: allVedtægter
    },
    {
        id: 'Regnskaber',
        title: 'Regnskaber',
        files: allRegnskaber
    },
    {
        id: 'Rapporter',
        title: 'Rapporter',
        files: allRapporter
    },
    {
        id: 'ØvrigeDokumenter',
        title: 'Øvrige dokumenter',
        files: allØverigeDokumenter
    }
];

export const DocumentViewer = (): JSX.Element => {
    const [expanded, setExpanded] = useState<string[]>([accordions[0].id]);
    const handleChange =
        (panel: string) =>
        (_event: React.SyntheticEvent, newExpanded: boolean): void => {
            if (newExpanded) {
                expanded.push(panel);
                setExpanded([...expanded, panel]);
            } else {
                setExpanded(expanded.filter((p) => p != panel));
            }
        };

    return (
        <>
            <section className={`${styles.bgText}`}>
                <div className={`container`}>
                    Velkommen til Højene Enge Grundejerforenings dokumentarkiv. Her har vi samlet alle relevante dokumenter for vores forening, så du
                    nemt kan få adgang til vigtig information. Du finder alt fra: vedtægter, der beskriver vores forenings formål og retningslinjer,
                    til referater fra vores møder, hvor beslutninger og diskussioner er nedfældet. Vi har også budgetter, der giver indblik i
                    foreningens økonomiske planlægning, samt andre væsentlige dokumenter, der er nødvendige for vores daglige drift. Vores mål er at
                    sikre gennemsigtighed og let tilgængelighed for alle medlemmer. Du kan derfor til enhver tid hente de nødvendige filer direkte fra
                    denne side, så du er opdateret med de seneste beslutninger og retningslinjer for vores fællesskab.
                </div>
            </section>
            <section className={`${styles.bg}`}>
                <div className={`container`}>
                    <div className={`${styles.accordionContainer}`}>
                        {accordions.map((accordion) => (
                            <Accordion expanded={expanded.includes(accordion.id)} onChange={handleChange(accordion.id)} key={accordion.id}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${accordion.id}-content`}
                                    id={`${accordion.id}-header`}
                                    className={`${styles.accordionSummary} ${expanded.includes(accordion.id) ? styles.active : ''}`}>
                                    {accordion.title}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box component={List} className={`${styles.fileListContainer}`}>
                                        {accordion.files.map((file) => (
                                            <ListItem
                                                key={file.name}
                                                className={`${styles.listItem}`}
                                                component='a'
                                                href={file.publicPath}
                                                download={file.downloadName}>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <ImageIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={file.name} secondary={file.date} />
                                            </ListItem>
                                        ))}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};
