import { ReactComponent as Boss } from 'src/assets/icons/boss.svg';
import { ReactComponent as Cashier } from 'src/assets/icons/cashier-machine.svg';
import { ReactComponent as Kasserer } from 'src/assets/icons/cashier-machine.svg';
import { ReactComponent as Coach1 } from 'src/assets/icons/coach-1.svg';
import { ReactComponent as CoachMany } from 'src/assets/icons/coach-many.svg';
import { ReactComponent as Person } from 'src/assets/icons/person.svg';
import { ReactComponent as Secretary } from 'src/assets/icons/secretary.svg';
import { ReactComponent as Suppleant } from 'src/assets/icons/swap1.svg';
import type { PersonRole } from 'src/data/persons/dto';
import { PersonRoleEnum } from 'src/data/persons/dto';
import { filterPersonsBasedOnRole, persons } from 'src/data/persons/persons';

import styles from './person-grid.module.scss';

export type PersonGridType = {
    includeRoles?: PersonRoleEnum[];
    centerRowItems?: boolean;
};

const getSvgIcon = (roles: PersonRole[]): JSX.Element | undefined => {
    if (!roles?.[0]?.role) {
        return undefined;
    }
    switch (roles?.[0]?.role) {
        case PersonRoleEnum.Hovedformand: {
            return <Boss />;
        }
        case PersonRoleEnum.Formand: {
            return <CoachMany />;
        }
        case PersonRoleEnum.NæstFormand: {
            return <Coach1 />;
        }
        case PersonRoleEnum.Hovedkasserer: {
            return <Cashier />;
        }
        case PersonRoleEnum.HovedSekretær: {
            return <Secretary />;
        }
        case PersonRoleEnum.Bestyrelsesmedlem: {
            return <Person />;
        }
        case PersonRoleEnum.Revisor: {
            return <Secretary />;
        }
        case PersonRoleEnum.Kasserer: {
            return <Kasserer />;
        }
        case PersonRoleEnum.Suppleant: {
            return <Suppleant />;
        }
        default: {
            return <Person />;
        }
    }
};

export const PersonGrid = ({ includeRoles, centerRowItems }: PersonGridType): JSX.Element => (
    <section className={`${styles.bg}`}>
        <div className={`container`}>
            <div className={`row ${centerRowItems ? 'justify-content-center' : ''} ${styles.personGridRow}`}>
                {filterPersonsBasedOnRole(persons, includeRoles).map((person) => (
                    <div key={person.id} className={`col-6 col-md-4 col-lg-3 ${styles.card}`}>
                        <div className={`${styles.imageContainer}`}>
                            <div className={`${styles.imageType}`} id='maer123'>
                                {getSvgIcon(person.roles)}
                                <span className={`${styles.titleText}`}>{person.roles?.[0]?.role}</span>
                            </div>
                            <img loading='lazy' src={person.image} alt={person.firstName} className={`${styles.cardImage}`} />
                        </div>
                        <div className={`${styles.textContainer}`}>
                            <h3>
                                {person.firstName} {person.surname}
                            </h3>
                            {person.vejNavn && (
                                <span>
                                    {person.vejNavn} {person.vejNummer}
                                </span>
                            )}
                            {person.by && (
                                <span>
                                    {person.postNummer} {person.by}
                                </span>
                            )}
                            {person.tlfNr && (
                                <span>
                                    <strong>Mobil: </strong>
                                    {person.tlfNr}
                                </span>
                            )}
                            {person.email && (
                                <span>
                                    <strong>Email: </strong>
                                    {person.email}
                                </span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
);
