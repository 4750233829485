import { ReactComponent as ArrowRight } from 'src/assets/icons/arrow-right.svg';
import { ColorTokens } from 'src/utilities/tokens/tokens';
import { Autoplay, EffectCreative, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import type { RichTextProps } from '../richtext/dto';
import { RichTextTypeEnum } from '../richtext/dto';
import { Richtext } from '../richtext/richtext';

import styles from './hero-carousel.module.scss';

type HeroCarouselType = {
    image: string;
    richtext: RichTextProps;
    link: string;
};

const heros: HeroCarouselType[] = [
    {
        image: 'pics/above1.jpg',
        richtext: {
            type: RichTextTypeEnum.document,
            marks: [],
            data: undefined,
            content: [
                {
                    type: RichTextTypeEnum.heading1,
                    marks: [],
                    data: undefined,
                    content: [
                        { type: RichTextTypeEnum.text, marks: [], value: 'Velkommen Til ', data: undefined },
                        { type: RichTextTypeEnum.text, marks: [], value: 'Højene Enge ', data: { color: ColorTokens.GREEN_DARK } },
                        { type: RichTextTypeEnum.text, marks: [], value: 'Grundejerforening', data: undefined }
                    ]
                },
                {
                    type: RichTextTypeEnum.text,
                    marks: [],
                    value: 'Højene Enge Grundejerforening, grundlagt 12. september 2021, er hjem for et nyt fællesskab, der spænder over XX matrikler beliggende i området Højene Øst imellem Højene hallen(SHI) og Ringvejen.',
                    data: undefined
                }
            ]
        },
        link: '/om'
    }
];

export const HeroCarousel = (): JSX.Element => (
    <Swiper
        modules={[Autoplay, Pagination, Navigation, EffectCreative]}
        loop={true}
        navigation={true}
        autoplay={{
            delay: 10000,
            disableOnInteraction: false
        }}
        pagination={{
            clickable: true,
            bulletClass: `${styles.aBullet}`,
            bulletActiveClass: `${styles.aBulletActive}`
        }}
        speed={1500}
        effect={'creative'}
        slidesPerView={1}
        creativeEffect={{
            prev: {
                shadow: true,
                translate: [0, 0, 0]
            },
            next: {
                translate: ['100%', 0, 0]
            }
        }}
        className={`${styles.swiper}`}>
        {heros.map((hero, index) => (
            <SwiperSlide key={hero.image} className={`${styles.swiperSlide}`}>
                <img src={hero.image} alt={`billed-${index}`}></img>
                <div className={`${styles.textContainer}`}>
                    <Richtext {...hero.richtext} className={`${styles.richtextContainer}`} />
                    <a className={`${styles.textLink}`} href={hero.link}>
                        <span className={`${styles.readMore}`}>Læs mere</span> <ArrowRight />
                    </a>
                </div>
            </SwiperSlide>
        ))}
    </Swiper>
);
