import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as List } from 'src/assets/icons/list.svg';
import { ReactComponent as XCircle } from 'src/assets/icons/x-circle.svg';
import { ReactComponent as Logo } from 'src/assets/logo/home.svg';

import styles from './nav-bar.module.scss';

export const NavBar = (): JSX.Element => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const closeMenu = (): void => {
        setMenuOpen(false);
    };

    const getNavigationItems = (): JSX.Element => (
        <>
            <li>
                <NavLink to={`/nyheder`} onClick={closeMenu}>
                    Nyheder
                </NavLink>
            </li>
            <li>
                <NavLink to={`/om`} onClick={closeMenu}>
                    Om
                </NavLink>
            </li>
            <li>
                <NavLink to={`/bestyrelsen`} onClick={closeMenu}>
                    Bestyrelsen
                </NavLink>
            </li>
            <li>
                <NavLink to={`/dokumenter`} onClick={closeMenu}>
                    Dokumenter
                </NavLink>
            </li>
        </>
    );

    return (
        <>
            <nav className={`${styles.navContainer} mainNavigation`} id='NavigationBarJyden'>
                <ul>
                    <li>
                        <NavLink to={`/`}>
                            <Logo />
                            <div className={`${styles.logoTextContainer}`}>
                                <span className={`${styles.logoTitle}`}>Højene Enge</span>
                                <span className={`${styles.logoBottomLine}`}>Grundejerforening</span>
                            </div>
                        </NavLink>
                    </li>
                    <div className={`${styles.showDesktop}`}>{getNavigationItems()}</div>
                    <div className={`${styles.showMobile}`}>
                        <button
                            className={`${styles.menuIcon}`}
                            onClick={(): void => {
                                setMenuOpen(!menuOpen);
                            }}>
                            {menuOpen ? <XCircle /> : <List />}
                        </button>
                    </div>
                </ul>
            </nav>
            {/* Overlay for mobile */}
            <div
                className={`${styles.clickOverlay} ${menuOpen ? styles.display : ''}`}
                onClick={closeMenu}
                onKeyDown={(e): void => {
                    if (e && (e.code === 'Space' || e.code === 'Enter' || e.code === 'Escape')) {
                        closeMenu();
                    }
                }}
                role='button'
                tabIndex={0}
            />
            <div className={`${styles.sideMenu} ${menuOpen ? styles.display : ''}`}>
                <ul>{getNavigationItems()}</ul>
            </div>
        </>
    );
};
