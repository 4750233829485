import YouTube from 'react-youtube';
import { useValueForBreakpoint } from 'src/utilities/use-value-for-breakpoint/use-value-for-breakpoint';
import { Autoplay, EffectCreative, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './video-carousel.module.scss';

const youtubeVideos = ['Izpit6BEqnA', 'MFAs3zFMtlo', '9-dyC5iZPbg', 'Lize9dLEWEc'];

export const VideoCarousel = (): JSX.Element => {
    const values = {
        xs: 1,
        sm: 1,
        md: 1.3,
        lg: 1.8,
        xl: 2.6
    };
    const value = useValueForBreakpoint({ values });

    return (
        <section className={`container-fluid ${styles.videoCarouselContainer}`}>
            <Swiper
                modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                navigation={true}
                pagination={{
                    clickable: true,
                    bulletClass: `${styles.aBullet}`,
                    bulletActiveClass: `${styles.aBulletActive}`
                }}
                spaceBetween={56}
                centeredSlides={true}
                loop={true}
                loopedSlides={1}
                slidesPerView={value}
                className={`${styles.swiper}`}>
                {youtubeVideos.map((id) => (
                    <SwiperSlide key={id} className={`${styles.swiperSlide}`}>
                        <YouTube videoId={id} opts={{ height: '100%', width: '100%' }} className={`${styles.youtubePlayer}`} />
                        <div className={`${styles.pagination}`} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
};
