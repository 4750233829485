import type { Breakpoint } from '../jydens-breakpoints/jydens-breakpoints';
import { jydensBreakpoints } from '../jydens-breakpoints/jydens-breakpoints';
import { useWindowDimension } from '../use-window-dimension/use-window-dimension';

type useValueForBreakpointProps<V> = {
    values: { [key in Breakpoint]: V };
};

export const useValueForBreakpoint = <V = unknown,>({ values }: useValueForBreakpointProps<V>): V => {
    const { width } = useWindowDimension();

    if (width < jydensBreakpoints.sm) {
        return values.xs;
    }

    if (width < jydensBreakpoints.md) {
        return values.sm;
    }

    if (width < jydensBreakpoints.lg) {
        return values.md;
    }

    if (width < jydensBreakpoints.xl) {
        return values.lg;
    }

    return values.xl;
};
