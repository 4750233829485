// import { Link } from 'react-router-dom';
import { ReactComponent as Group } from 'src/assets/icons/connected-people-icon.svg';
import { ReactComponent as Facebook } from 'src/assets/icons/facebook.svg';
import { latestReferat, latestRegnskab, latestVedtægter } from 'src/data/files/files';

import styles from './footer.module.scss';

export const Footer = (): JSX.Element => (
    <footer className={`${styles.footer}`}>
        <div className={`container-fluid`}>
            <div className={`row ${styles.footerRefRow}`}>
                <div className={`${styles.footerBarRefContainer} col-12`}>
                    <div className={``}>
                        <div className={`${styles.title}`}>Adresse</div>
                        <a href='https://www.google.com/maps/place/H%C3%B8jene+Enge,+9800+Hj%C3%B8rring/@57.4787413,10.0027416,17z/data=!3m1!4b1!4m6!3m5!1s0x4648ce8192b717d9:0x62dac9d68239cad9!8m2!3d57.4787384!4d10.0053165!16s%2Fg%2F11t1c8rj_k?entry=ttu'>
                            Grundejerforeningen <br />
                            Højene Enge <br />
                            9800 Hjørring
                        </a>
                    </div>
                    <div className={``}>
                        <div className={`${styles.title}`}>Mød os på de social medier</div>
                        <a href='https://www.facebook.com/Hoejeneenge?locale=dk'>Officiel side</a>
                        <a href='https://www.facebook.com/groups/2060053264056103/?hoisted_section_header_type=recently_seen&multi_permalinks=7931855253542512'>
                            Gruppe for medlemmer
                        </a>
                        <a href='https://xn--hjene-enge-0cb.dk/'>Salgs siden</a>
                        <a href='https://www.proff.dk/firma/grundejerforeningen-h%C3%B8jene-enge/hj%C3%B8rring/medlemsorganisationer/0PEKTDI10PU'>
                            Proff
                        </a>
                    </div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`${styles.footerBar} col-12`}>
                    <a href={latestVedtægter.publicPath} download={latestVedtægter.downloadName}>
                        Vedtægter
                    </a>
                    <a href={latestRegnskab.publicPath} download={latestRegnskab.downloadName}>
                        Regnskab
                    </a>
                    <a href={latestReferat.publicPath} download={latestReferat.downloadName}>
                        Referat
                    </a>
                    {/* <Link to={{ pathname: `/boksning`, hash: 'jydens-faq' }}>Spørgsmål</Link> */}
                    <a href='https://www.facebook.com/Hoejeneenge?locale=dk'>
                        <Facebook />
                    </a>
                    <a href='https://www.facebook.com/groups/2060053264056103/?hoisted_section_header_type=recently_seen&multi_permalinks=7931855253542512'>
                        <Group />
                    </a>
                </div>
            </div>
        </div>
    </footer>
);
