import { PersonGrid } from 'src/components/person-grid/person-grid';
import type { RichTextProps } from 'src/components/richtext/dto';
import { MarksEnum, RichTextTypeEnum } from 'src/components/richtext/dto';
import { Richtext } from 'src/components/richtext/richtext';
import { ColorTokens } from 'src/utilities/tokens/tokens';

const hovedbestyrelsen: RichTextProps = {
    type: RichTextTypeEnum.document,
    marks: [],
    data: { textAlign: 'center', margin: '0px 32px' },
    content: [
        {
            type: RichTextTypeEnum.heading2,
            marks: [],
            data: undefined,
            content: [{ type: RichTextTypeEnum.text, marks: [], value: 'Bestyrelsen ', data: { color: ColorTokens.GREEN_DARK } }]
        },
        {
            type: RichTextTypeEnum.text,
            marks: [{ type: MarksEnum.italic }],
            value: 'Højene Enge Grundejerforenings bestyrelse består af fem forskellige roller: formand, bestyrelsesmedlemmer, revisor, kasserer og suppleanter. Sammen varetager de foreningens interesser. Fordelingen af rollerne kan ses nedenfor.',
            data: { color: ColorTokens.GREY }
        }
    ]
};

export const Bestyrelsen = (): JSX.Element => (
    <>
        <section style={{ backgroundColor: ColorTokens.WHITE, display: 'flex' }}>
            <div className={`container`}>
                <Richtext {...hovedbestyrelsen} />
            </div>
        </section>
        <PersonGrid centerRowItems={true} />
    </>
);
