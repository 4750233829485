import { useEffect } from 'react';

export const useWindowResize = (callback: EventListener): void => {
    useEffect(() => {
        window.addEventListener('resize', callback);
        return (): void => {
            window.removeEventListener('resize', callback);
        };
    }, [callback]);
};
