import type { CSSProperties } from 'react';

// Marks
export enum MarksEnum {
    bold = 'bold',
    underline = 'underline',
    italic = 'italic'
}

export type Marks = {
    type: MarksEnum;
};

// RichText data
export type RichTextData = CSSProperties | undefined;

export enum RichTextTypeEnum {
    text = 'text',
    image = 'image',
    paragraph = 'paragraph',
    hr = 'hr',
    heading1 = 'heading1',
    heading2 = 'heading2',
    heading3 = 'heading3',
    document = 'document'
}

export type RichTextContent = TextContent | ElementContent | ImageContent;

export type TextContent = {
    type: RichTextTypeEnum.text;
    marks: Marks[];
    value: string;
    data: RichTextData;
};

export enum ImageAlignmentEnum {
    left = 'left',
    right = 'right',
    center = 'center'
}

export type ImageOptions = {
    alt: string;
    alignment?: ImageAlignmentEnum;
};

export type ImageContent = {
    type: RichTextTypeEnum.image;
    options?: ImageOptions;
    value: string;
    data: RichTextData;
};

export type ElementContent = {
    type: Omit<RichTextTypeEnum, RichTextTypeEnum.text | RichTextTypeEnum.image>;
    content: RichTextContent[];
    data: RichTextData;
    marks: Marks[];
};

export type RichTextProps = {
    type: RichTextTypeEnum.document;
    marks: Marks[];
    data: RichTextData;
    content: RichTextContent[];
    className?: string;
};
