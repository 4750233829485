export enum Breakpoint {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl'
}

export const jydensBreakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

export const jydensBreakpointsPx = {
    xs: `${jydensBreakpoints.xs}px`,
    sm: `${jydensBreakpoints.sm}px`,
    md: `${jydensBreakpoints.md}px`,
    lg: `${jydensBreakpoints.lg}px`,
    xl: `${jydensBreakpoints.xl}px`
};
