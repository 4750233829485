import type { FileProps } from './dto';

// Øvrige Dokumenter
export const vedtagetLokalplan2019: FileProps = {
    publicPath: 'docs/øvrige-dokumenter/Vedtaget lokalplan.pdf',
    downloadName: 'Vedtaget lokalplan.pdf',
    name: 'Vedtaget lokalplan',
    date: '26 Juni, 2019'
};

// Referater
export const ordinærReferat2023: FileProps = {
    publicPath: 'docs/referat/ordinærReferat2023.pdf',
    downloadName: 'Referat af ordinær generalforsamling i Grundejerforeningen Højene Enge 2023.pdf',
    name: 'Referat af ordinær generalforsamling i Grundejerforeningen Højene Enge 2023',
    date: '24 August, 2023'
};

// Regnskab
export const regnskab2021: FileProps = {
    publicPath: 'docs/regnskab/regnskab2021.pdf',
    downloadName: 'Regnskab for Grundejerforeningen Højene Enge 2021.pdf',
    name: 'Regnskab for Grundejerforeningen Højene Enge 2021',
    date: '31 December, 2021'
};

export const regnskab2022: FileProps = {
    publicPath: 'docs/regnskab/regnskab2022.pdf',
    downloadName: 'Regnskab for Grundejerforeningen Højene Enge 2022.pdf',
    name: 'Regnskab for Grundejerforeningen Højene Enge 2022',
    date: '31 December, 2022'
};

export const regnskab2023: FileProps = {
    publicPath: 'docs/regnskab/regnskab2023.pdf',
    downloadName: 'Regnskab for Grundejerforeningen Højene Enge 2023.pdf',
    name: 'Regnskab for Grundejerforeningen Højene Enge 2023',
    date: '31 December, 2023'
};

// Rapporter
export const jordforureningsattest2018: FileProps = {
    publicPath: 'docs/rapporter/Jordforureningsattest.pdf',
    downloadName: 'Jordforureningsattest.pdf',
    name: 'Jordforureningsattest',
    date: '19 Juni, 2018'
};

export const forundersøgelsesrapport2017: FileProps = {
    publicPath: 'docs/rapporter/Forundersøgelsesrapport fra Vendsyssel Historiske Museum.pdf',
    downloadName: 'Forundersøgelsesrapport fra Vendsyssel Historiske Museum.pdf',
    name: 'Forundersøgelsesrapport fra Vendsyssel Historiske Museum',
    date: '14 Juni, 2017'
};

export const vejledendeGeotekniskRapport2017: FileProps = {
    publicPath: 'docs/rapporter/Vejledende geoteknisk rapport.pdf',
    downloadName: 'Vejledende geoteknisk rapport.pdf',
    name: 'Vejledende geoteknisk rapport',
    date: '17 Februar, 2017'
};

// Vedtægter
export const vedtægter2023: FileProps = {
    publicPath: 'docs/vedtægter/vedtægter2023.pdf',
    downloadName: 'Vedtægter for Grundejerforeningen Højene Enge 2023.pdf',
    name: 'Vedtægter for Grundejerforeningen Højene Enge 2023',
    date: '29 September, 2019'
};

// All
export const allØverigeDokumenter = [vedtagetLokalplan2019];
export const allReferater = [ordinærReferat2023];
export const allRegnskaber = [regnskab2023, regnskab2022, regnskab2021];
export const allRapporter = [jordforureningsattest2018, forundersøgelsesrapport2017, vejledendeGeotekniskRapport2017];
export const allVedtægter = [vedtægter2023];

// Latest
export const latestVedtægter = vedtægter2023;
export const latestRegnskab = regnskab2023;
export const latestReferat = ordinærReferat2023;
