import React from 'react';
import ReactDOM from 'react-dom/client';

import { Router } from './pages/router';

import 'src/styles/main.scss';

const root = ReactDOM.createRoot(document.getElementById('HEngeRoot') as HTMLElement);
root.render(
    <React.StrictMode>
        <Router />
    </React.StrictMode>
);
