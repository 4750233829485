import type { ElementContent, ImageContent, Marks, RichTextContent, RichTextProps, TextContent } from './dto';
import { ImageAlignmentEnum } from './dto';
import { MarksEnum, RichTextTypeEnum } from './dto';

import styles from './richtext.module.scss';

const getMarkStyleClasses = (marks: Marks[]): string => {
    if (!marks || !Array.isArray(marks)) {
        return '';
    }
    return marks.reduce((prev, mark) => {
        switch (mark.type) {
            case MarksEnum.bold: {
                prev = prev.concat(`${styles.bold} `);
                break;
            }
            case MarksEnum.underline: {
                prev = prev.concat(`${styles.underline} `);
                break;
            }
            case MarksEnum.italic: {
                prev = prev.concat(`${styles.italic} `);
                break;
            }
            default:
        }
        return prev;
    }, '');
};

const renderTextContent = (tContent: TextContent, key: number): JSX.Element | undefined => {
    if (!tContent) {
        return undefined;
    }
    return (
        <span key={key} style={tContent.data} className={`${getMarkStyleClasses(tContent.marks)}`}>
            {tContent.value}
        </span>
    );
};

const getImageAlignment = (alignment: ImageAlignmentEnum | undefined): string => {
    switch (alignment) {
        case ImageAlignmentEnum.right: {
            return styles.imageRight;
        }
        case ImageAlignmentEnum.left: {
            return styles.imageLeft;
        }
        default: {
            return styles.imageCenter;
        }
    }
};

const renderImageContent = (tContent: ImageContent, key: number): JSX.Element | undefined => {
    if (!tContent.value) {
        return undefined;
    }
    return (
        <>
            <img
                key={key}
                style={tContent.data}
                alt={tContent.options?.alt ?? 'picture'}
                src={tContent.value}
                className={`${getImageAlignment(tContent.options?.alignment)}`}
            />
        </>
    );
};

const renderElement = (eContent: ElementContent, key: number): JSX.Element | undefined => {
    if (!eContent) {
        return undefined;
    }
    switch (eContent.type) {
        case RichTextTypeEnum.heading1: {
            return (
                <h1 key={key} className={`${getMarkStyleClasses(eContent.marks)} ${styles.h1}`}>
                    {eContent?.content.map((c, index) => renderContent(c, index + 999))}
                </h1>
            );
        }
        case RichTextTypeEnum.heading2: {
            return (
                <h2 key={key} className={`${getMarkStyleClasses(eContent.marks)} ${styles.h2}`}>
                    {eContent?.content.map((c, index) => renderContent(c, index + 999))}
                </h2>
            );
        }
        case RichTextTypeEnum.heading3: {
            return (
                <h3 key={key} className={`${getMarkStyleClasses(eContent.marks)} ${styles.h3}`}>
                    {eContent?.content.map((c, index) => renderContent(c, index + 999))}
                </h3>
            );
        }
        default: {
            return undefined;
        }
    }
};

const renderContent = (c: RichTextContent, key: number): JSX.Element | undefined => {
    if (c.type === RichTextTypeEnum.text) {
        return renderTextContent(c as TextContent, key);
    }
    if (c.type === RichTextTypeEnum.image) {
        return renderImageContent(c as ImageContent, key);
    }
    return renderElement(c as ElementContent, key);
};

export const Richtext = (props: RichTextProps): JSX.Element => {
    if (props?.type !== RichTextTypeEnum.document && props.content) {
        return <></>;
    }

    return (
        <div style={props.data} className={`${getMarkStyleClasses(props.marks)} ${props.className}`}>
            {props.content.map((c, index) => renderContent(c, index))}
        </div>
    );
};
