import { useCallback, useEffect, useState } from 'react';

import { useWindowResize } from '../use-window-resize/use-window-resize';

type useWindowDimensionReturnType = {
    width: number;
    height: number;
};

export const useWindowDimension = (): useWindowDimensionReturnType => {
    const [dimension, setDimension] = useState({ width: 0, height: 0 });
    const handleResize = useCallback((): void => {
        setDimension({ width: window.innerWidth, height: window.innerHeight });
    }, []);
    useWindowResize(handleResize);
    useEffect(() => {
        handleResize();
    }, [handleResize]);
    return {
        width: dimension.width,
        height: dimension.height
    };
};
