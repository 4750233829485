export enum ColorTokens {
    BLUE = 'var(--blue)',
    BLUE_MEDIUM = 'var(--blue-medium)',
    BLUE_LIGHT = 'var(--blue-light)',
    GREEN_DARK = 'var(--green-dark)',
    GREEN = 'var(--green)',
    GREEN_MEDIUM = 'var(--green-medium)',
    GREEN_LIGHT = 'var(--green-light)',
    YELLOW = 'var(--yellow)',
    YELLOW_MEDIUM = 'var(--yellow-medium)',
    YELLOW_LIGHT = 'var(--yellow-light)',
    ORANGE = 'var(--orange)',
    ORANGE_MEDIUM = 'var(--orange-medium)',
    ORANGE_LIGHT = 'var(--orange-light)',
    PURPLE = 'var(--purple)',
    PURPLE_MEDIUM = 'var(--purple-medium)',
    PURPLE_LIGHT = 'var(--purple-light)',
    RED_DARK = 'var(--red-dark)',
    RED = 'var(--red)',
    RED_MEDIUM = 'var(--red-medium)',
    RED_LIGHT = 'var(--red-light)',
    NIGHT = 'var(--night)',
    GREY_DARKEST = 'var(--grey-darkest)',
    GREY_DARK = 'var(--grey-dark)',
    GREY = 'var(--grey)',
    GREY_MEDIUM = 'var(---grey-medium)',
    GREY_LIGHT = 'var(--grey-light)',
    GREY_LIGHTEST = 'var(--grey-lightest)',
    WHITE = 'var(--white)',
    NIGHT_GRADIENT = 'var(--night-gradient)',
    BLUE_GRADIENT = 'var(--blue-gradient)',
    GREEN_GRADIENT = 'var(--green-gradient)',
    YELLOW_GRADIENT = 'var(--yellow-gradient)',
    ORANGE_GRADIENT = 'var(--orange-gradient)',
    PURPLE_GRADIENT = 'var(--purple-gradient)',
    RED_GRADIENT = 'var(--red-gradient)'
}
