import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Footer } from 'src/components/footer/footer';
import { NavBar } from 'src/components/nav-bar/nav-bar';
import { Bestyrelsen } from 'src/pages/bestyrelsen/bestyrelsen';
import { Forside } from 'src/pages/forside/forside';
import { Nyheder } from 'src/pages/nyheder/nyheder';
import { Om } from 'src/pages/om/om';
import { PageNotFound } from 'src/pages/page-not-found/page-not-found';

import { Dokumenter } from './dokumenter/dokumenter';

export const Router = (): JSX.Element => (
    <BrowserRouter>
        <NavBar />
        <Routes>
            <Route index element={<Forside />} />
            <Route index path='/' element={<Forside />} />
            <Route path='nyheder' element={<Nyheder />} />
            <Route path='om' element={<Om />} />
            <Route path='bestyrelsen' element={<Bestyrelsen />} />
            <Route path='dokumenter' element={<Dokumenter />} />
            <Route path='*' element={<PageNotFound />} />
        </Routes>
        <Footer />
    </BrowserRouter>
);
